import "./app.css"

import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Suspense } from "react";

export default function Root() {
  return (
    <>
      <BrowserRouter basename="barcodeprinter">
        <Suspense fallback={<div>Loading... </div>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </>
  );
}

