import { gql } from "@apollo/client";

export const QUERY_SETTINGS = gql`
query QUERY_SETTINGS{
  basicWebsiteConfigurations{
    id
    pk
    getContentTypeId
    title
    timezone
    currency
    emailFrom
    schema
    adminEmails{
      id
      pk
      title
      email
    }
    taxRate{
      title
      pk
      rate
    }
    serviceCharge{
      title
      pk
    }
    country
    faviconIcon
    logo
    isVirtualKeyboard
    kitchenPrinter{
      pk
      name
      location
      type
    }
    defaultPrinter{
      pk
      name
      location
      type
    }
    des{
      pk
      fromEmail
    }
    oces{
      pk
      fromEmail
    }
    ams{
      pk
      fromEmail
    }
    initialSetup
    allowSignup
    calendarType
    isAutoPrint
    isProductWisePrint
    thankYouMessage{
      pk
      shortDescription
      longDescription
    }
  }
}
`;
export const BARCODE_SETTING = gql`
query{
  barcodeSetting{
    id
    pk
    content
  }
}
`;
export const UPDATAE_CREATE_BARCODE_CONTENT = gql`
mutation($content:JSONString){
  createUpdateBarcodeContent(input:{
    content:$content
  }){
    errors
    success
    barcode{
      content
    }
  }
}
`;
export const DOWNLOAD_BARCODE = gql`
mutation($productId:String!){
  downloadBarcode(input:{
    productId:$productId
  }){
    success
    errors
    barcodeHtmlString
  }
}
`;

export const CREATE_EMAIL = gql`
mutation($objectId:String, $contentType:String, $title:String, $description:String, $email:String, $isVerified:String) {
  createEmail(
    input: {
      objectId:$objectId
      contentType:$contentType
      title:$title
      email:$email
      isVerified: $isVeridied
    }
  ) {
    errors
    success
    email {
      id
      title
      isVerified
    }
  }
}
`;

export const BULK_CREATE_EMAIL = gql`
mutation($objectId:String, $contentType:String,$emails:[EmailInput]){
  bulkCreateEmail(input:{
    contentType: $contentType,
    objectId: $objectId,
    emails: $emails
  }){
    success
    errors
    email{
    pk
      email
      title
      isVerified
    }
  }
}
`;

export const QUERY_EMAILS = gql`
query($first:Int,$after:String,$before:String,$offset:Int,$title_Icontains:String){
  userEmails(first:$first,after: $after, before: $before,offset:$offset,title_Icontains:$title_Icontains){
    edges{
      node{
        id
        pk
        title
        email
      }
    }
  }
}
`;
export const SINGLE_EMAIL = gql`
query($id:ID!){
  userEmail(id:$id){
    id
    pk
    contentType{
      id
      pk
    }
    title
    objectId
    email
    isVerified
  }
}
`;

export const UPDATE_EMAIL = gql`
mutation($id:String!,$objectId:String!,$contentType:String,$title:String!,$email:String!,$isVerified:String){
  updateEmail(input:{
    id:$id,
    title:$title,
  email:$email,
  isVerified:$isVerified,
  objectId:$objectId,
  contentType:$contentType,
}){
    errors
    success
  }
}
`;

export const DELETE_EMAIL = gql`
mutation($objectId:String!){
  deleteEmail(input:{objectId:$objectId}){
    errors
    success
  }
}
`;

export const PERIODIC_TASKS = gql`
query($first:Int,$after:String,$before:String,$offset:Int,$name_Icontains:String){
  periodicTasks(first:$first,after: $after, before: $before,offset:$offset,name_Icontains:$name_Icontains){
     pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges{
      node{
        id
        pk
        name      
        task
        startTime
        expires
        enabled
    }
  }
}
}
`;



export const CREATE_PERIODIC_TASKS = gql`
mutation($name:String,$task:String,$interval:String,$startTime:String,$expires:String,$description:String, $enabled:String){
    createPeriodicTask(input:{
      name:$name,
      task:$task,
      interval:$interval,
      startTime:$startTime,
      expires:$expires,
      description:$description
      enabled:$enabled
    }){
      errors
      success
    }
  }
`;
export const SINGLE_PERIODIC_TASK = gql`
query($id:ID!){
  periodicTask(id:$id){
    id
    pk
    name 
    task
    interval{
      id
      pk
      period
      every
    }
    startTime
    expires
    description
    enabled
  }
}
`;
export const UPDATE_PERIODIC_TASKS = gql`
mutation($name:String,$task:String,$interval:String,$startTime:String,$expires:String,$description:String,$taskId:String!, $enabled:String){
    updatePeriodicTask(input:{
      name:$name,
      task:$task,
      interval:$interval,
      startTime:$startTime,
      expires:$expires,
      description:$description
      taskId:$taskId
      enabled:$enabled
    }){
      errors
      success
    }
  }
`;
export const DELETE_PREODIC_TASK = gql`
mutation($objectId:String!){
  deletePeriodicTask(input:{objectId:$objectId}){
    errors
    success
  }
}
`;

export const REGISTERED_TASKS = gql`
query{
  registeredTasks
}
`;
export const PERIODIC_TASKS_INTERVAL = gql`
query($first:Int,$offset:Int,$period_Icontains:String){
  periodicTaskIntervals(first:$first,offset:$offset,period_Icontains:$period_Icontains){
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges{
      node{
        id
        pk
        period
        every
      }
    }
  }
}
`;

export const CREATE_PERIODIC_TASKS_INTERVAL = gql`
mutation($every:String,$period:String){
  createPeriodicTaskInterval(input:{
    every:$every,
    period:$period
  }){
    errors
    success
  }
}
`;
export const UPDATE_PERIODIC_TASKS_INTERVAL = gql`
mutation($every:String,$period:String,$intervalId:String!){
  updatePeriodicTaskInterval(input:{
    every:$every,
    period:$period
    intervalId:$intervalId
  }){
    errors
    success
  }
}
`;

export const SINGLE_PERIODIC_TASK_INTERVAL = gql`
query($id:ID!){
  periodicTaskInterval(id:$id){
    id
    pk
    every
    period
  }
}`;
export const DELETE_PREODIC_INTERVAL = gql`
mutation($objectId:String!){
  deletePeriodicTaskInterval(input:{objectId:$objectId}){
    errors
    success
  }
}
`;

export const PAYMENT_SERVICES = gql`
query($first:Int,$after:String,$before:String,$offset:Int,$name_Icontains:String){
  paymentServices(first:$first,after: $after, before: $before,offset:$offset,name_Icontains:$name_Icontains){
    edges{
      node{
        id
        pk
        created
        isActive
        slug
        name
        account{
          id
          pk
          name
        }
      }
    }
  }
}
`;

export const UPDATE_PAYMENT_SERVICES = gql`
mutation($objectId:String!,$data:String,$isActive:String,$account:String){
  updatePaymentService(input:{
    objectId:$objectId,
    data:$data,
    isActive:$isActive,
    account:$account
  }){
    errors
    success
  }
}
`;

export const SINGLE_PAYMENT_SERVICES = gql`
query($id:ID!){
  paymentService(id:$id){
    id
    pk
    name
    slug
    isActive
    data
    account{
      id
      pk
    }
  }
}
`;



export const SETUP_LINKLY_DEVICE = gql`
mutation($username:String!, $password:String!, $pairCode:String!){
  setupLinklyDevice(input:{
    username:$username,
    password:$password,
    pairCode:$pairCode
  }){
    errors
    success
    message
  }
}
`;
export const DELIVER_METHODS = gql`
query($first:Int,$after:String,$before:String,$offset:Int,$title_Icontains:String){
  deliveryMethods(first:$first,after: $after, before: $before,offset:$offset,title_Icontains:$title_Icontains){
    edges{
      node{
        id
        pk
        createdBy{
          id
          username
        }
        title
        amount
        description
      }
    }
  }
}
`;
export const CREATE_DELIVERY_METHODS = gql`
mutation($title:String, $description:String, $amount:String){
  createDeliveryMethod(input:{
    title:$title,
    description:$description,
    amount:$amount
  }){
    errors
    success
  }
}
`;
export const UPDATE_DELIVERY_METHODS = gql`
mutation($title:String, $description:String, $amount:String, $deliveryMethodId:String!){
  updateDeliveryMethod(input:{
    title:$title,
    description:$description,
    amount:$amount,
    deliveryMethodId:$deliveryMethodId
  }){
    errors
    success
  }
}
`;
export const SINGLE_DELIVERY_METHOD = gql`
query($id:ID!){
  deliveryMethod(id:$id){
    id
    pk
    created
    title
    description
    amount
  }
}
`;
export const DELETE_DELIVERY_METHOD = gql`
mutation($objectId:String!){
  deleteDeliveryMethod(input:{objectId:$objectId}){
    errors
    success
  }
}
`;

export const GET_API_KEY = gql`
query{
  getApiKey
}
`;

export const GENERTAE_API_KEY = gql`
mutation{
  generateApiKey(input:{}){
    errors
    success
    token{
      id
      pk
      accessKey
    }
    message
  }
}
`;

